import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"
import { StaticQuery, graphql } from "gatsby"

const GlobalGAP = ({ contentField }) => {
  const lang = "de"
  const title = contentField.title
  const seo = contentField.seo
  const image = contentField.featuredImage

  return (
    <Layout
      lang={lang}
      translationEN="/certifications/"
      translationES="/es/certificaciones/"
      translationIT="/it/certificazioni-di-qualita/"
      translationFR="/fr/certificats/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title={title}
        image={require("../../assets/images/globalgap-back.jpg")}
      />

      <div className="main-section">
        <div className="text-container">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <img
                src={require("../../assets/images/BAP_Certificate.jpg")}
                alt=""
                className="img-fluid"
              />
            </div>{" "}
            <div className="col-md-4">
              <img
                src={require("../../assets/images/globalgap-logo-green.png")}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div dangerouslySetInnerHTML={{ __html: contentField.content }} />
        </div>
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxMzk5" }) {
          title
          content
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <GlobalGAP {...data} />}
  />
)
